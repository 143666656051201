<template>
  <BaseDialog
    v-bind="$attrs"
    title="系統生成設定"
    width="600px"
    :btn1Loading="loading"
    @confirm="onConfirm"
    @close="$emit('close')"
  >
    <p class="modal-hint-text">系統將以您的需求隨機生成</p>
    <el-form
      ref="formRef"
      :model="formData"
      :rules="formRules"
      label-position="top"
    >
      <el-form-item prop="quantity">
        <div class="flex" style="gap: 20px">
          <p>{{ `生成幾筆${serialCodeName}` }}</p>
          <el-input v-model="formData.quantity" placeholder="請輸入" />
          <p>筆</p>
        </div>
      </el-form-item>
      <el-form-item prop="codeLength">
        <div class="flex" style="gap: 20px">
          <p>{{ `每筆${serialCodeName}長度` }}</p>
          <el-input
            v-model="formData.codeLength"
            :placeholder="`請輸入（最多 ${max} 碼）`"
          />
          <p>碼</p>
        </div>
      </el-form-item>

      <el-form-item prop="chartset">
        <template slot="label">
          <FormItemTooltipLabel label="組成格式" :tooltipWidth="200">
            系統生成時，會避免易混淆的字元會排除以英文大寫「O」、「I」、「B」以及英文小寫「o」與「l」字符生成
          </FormItemTooltipLabel>
        </template>

        <el-checkbox-group v-model="formData.chartset">
          <div class="options-container">
            <el-checkbox
              v-for="option in chartsetOptions"
              :key="option.value"
              :label="option.value"
            >
              {{ option.label }}
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
  </BaseDialog>
</template>

<script>
import { defineComponent, computed } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules, lengthRules, isDigitRules } from '@/validation'

export default defineComponent({
  name: 'SerialCodeSystemGernateModal',
  components: { BaseDialog, FormItemTooltipLabel },
  props: {
    max: { type: [Number, String], default: 18 },
    min: { type: [Number, String], default: 1 },
    serialCodeName: { type: String, default: '序號' },
    handleSubmit: Function,
  },
  emits: ['close', 'refresh', 'done'],
  setup (props, { emit }) {
    const { initFormData, formData, formRef, onSubmit, loading } = useBaseForm()
    initFormData({
      quantity: null,
      codeLength: null,
      chartset: [],
    })

    const formRules = computed(() => ({
      quantity: [noEmptyRules(), isDigitRules()],
      codeLength: [
        noEmptyRules(),
        isDigitRules(),
        lengthRules(Number(props.min), Number(props.max)),
      ],
      chartset: [noEmptyRules()],
    }))

    const compactData = computed(() => {
      return {
        count: formData.quantity,
        length: formData.codeLength,
        useUpperCase: formData.chartset.includes('useUpperCase'),
        useLowerCase: formData.chartset.includes('useLowerCase'),
        useNumber: formData.chartset.includes('useNumber'),
      }
    })

    const onConfirm = async () => {
      await onSubmit(props.handleSubmit, compactData.value, { emit, loading })
    }

    const chartsetOptions = [
      { label: '英文大寫（系統將自動排除 O、I、B）', value: 'useUpperCase' },
      { label: '英文小寫（系統將自動排除 o、l）', value: 'useLowerCase' },
      { label: '數字  (0~9)', value: 'useNumber' },
    ]
    return {
      formData,
      formRef,
      formRules,
      onConfirm,
      loading,
      chartsetOptions,
      compactData,
    }
  },
})
</script>

<style lang="postcss" scoped>
.options-container {
  @apply flex flex-col;
}

.modal-hint-text {
  @apply text-info text-normal mb-[30px];
}

::v-deep .el-input {
  @apply w-[170px];
}
</style>
